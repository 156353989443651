/**
 * 注册页面
 */
export function getActivityRegisterRoutePath(inviteCode?: string) {
  return inviteCode ? `/register?invitationCode=${inviteCode}` : '/register'
}

/**
 * 福利中心页面
 */
export function getWelfareCenterRoutePath() {
  return '/welfare-center'
}

/**
 * 设置页面
 */
export function getSettingsRoutePath() {
  return '/personal-center/settings'
}

/**
 * 绑定账号
 */
export function getBindAccountRoutePath() {
  return '/personal-center/account-security/bind-account'
}

/**
 * 安全项验证不可用
 */
export function getSafetyRoutePath(popup?: boolean, type?: string) {
  if (popup) {
    return `/safety-items?popup=${type}`
  }
  return '/safety-items'
}
